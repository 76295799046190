import request from "@/utils/request";

// 日语APP每日一句列表
export function DailyOneList(params) {
    return request({
        url: '/admin/korean_v3/dailyOneList',
        method: 'get',
        params
    })
}

// 删除日语APP每日一句
export function DailyOneDel(data) {
    return request({
        url: '/admin/korean_v3/dailyOneDel',
        method: 'delete',
        data
    })
}

// 添加日语APP每日一句
export function DailyOneAdd(data) {
    return request({
        url: '/admin/korean_v3/dailyOneAdd',
        method: 'post',
        data
    })
}

// 编辑APP每日一句
export function DailyOneEdit(data) {
    return request({
        url: '/admin/korean_v3/dailyOneEdit',
        method: 'put',
        data
    })
}

// 每日一句上传COS签名
export function getDailyOneCosToken(params) {
    return request({
        url: '/admin/korean_v3/getDailyOneCosToken',
        method: 'get',
        params
    })
}
